import React, { useState } from "react";
import {Paper, TextField, Grid, Button, Typography, Dialog} from '@mui/material';
import * as yup from "yup";
import { useHistory, useLocation, Redirect} from "react-router-dom";
import { useFormik } from 'formik';
import "./styles.css";
import { useAuth } from "../hooks/useAuth";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import Sisense from '../resources/Sisense.png'

const actions = [
    <Button
        label="Cancel"
        primary={true}
        // onTouchTap={handleClose}
    />,
    <Button
        label="Submit"
        primary={true}
        keyboardFocused={true}
        // onTouchTap={handleClose}
    />,
];

function Login() {
    const { signin } = useAuth();

    let history = useHistory();
    let location = useLocation();

    const [forgotPasswordFormOpen, setForgotPasswordFormOpen] = useState(false);


    let { from } = location.state || { from: { pathname: "/" } };

    const validationSchema = yup.object({
        email: yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        password: yup.string()
            .required("Required")
    });

    const closeForgotPasswordForm = (email) => {
        setForgotPasswordFormOpen(false);
        if (email) {
            formik.setValues({ email }); // Set the email in the formik state
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                await signin(values.email, values.password);
                setSubmitting(false);
                history.replace(from);
            } catch (e) {
                setErrors({'general': e.data || e.message});
            }
        }
    })

    //todo add redirects when token is expired (server-side should return appropriate error code)
    return (
        <div>
            <img alt={'Sisense'} src={Sisense} className='loginLogo'/>

            <form onSubmit={formik.handleSubmit} className="loginForm">

                <Typography variant="h5" style={{marginTop: '15px', textAlign: 'center'}}>Login to My Sisense</Typography>
                <TextField
                name="email"
                label={"Email"}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
            />
            <TextField
                type= "password"
                name="password"
                label={"Password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
            />
            {formik.errors.general && <span style={{color: 'red', display: 'block', margin: '10px 0 10px 0'}}>{formik.errors.general}</span>}
            <Button variant="outlined" type="submit">
                Submit
            </Button>
            <Typography style={{margin: '7px'}}>
                <Button onClick={() => setForgotPasswordFormOpen(true)}>Forgot Password</Button>
            </Typography>
            {formik.values.email && <Typography fontSize={12} style={{textAlign: 'center'}}>A password recovery email been sent to that address: {formik.values.email}</Typography>}
            </form>

            <Dialog
                title="Updating password"
                actions={actions}
                open={forgotPasswordFormOpen}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        closeForgotPasswordForm(formik.values.email);
                    }
                }}
            >
                <ForgotPasswordForm handleClose={closeForgotPasswordForm} email={formik.values.email}/>
            </Dialog>
        </div>
    );
}

export default Login;
